


































import { MODULES } from '@/constants';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';

@Component({
    components: {
        DarkModeButton: () => import('@/components/DarkModeButton.vue'),
        LogoutButton: () => import('@/components/LogoutButton.vue'),
    },
})
export default class AppBar extends Vue {
    drawer = false;

    get tokenVerificationStatus(): string {
        return this.$store.getters[
            `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.GETTER.TOKEN_VERIFICATION_STATUS}`
        ];
    }

    get menuItems(): RouteConfig[] {
        const routes = this.$router.options.routes;

        if (!routes) return [];

        return routes.filter((route: RouteConfig) => {
            if (!route.meta) return false;

            if (
                this.tokenVerificationStatus !==
                MODULES.AUTH.TOKEN_VERIFICATION_STATUS.TOKEN_VERIFIED
            ) {
                return (
                    route.meta &&
                    route.meta.anchor &&
                    route.meta.icon &&
                    !route.meta.private
                );
            }

            if (
                this.tokenVerificationStatus ===
                MODULES.AUTH.TOKEN_VERIFICATION_STATUS.TOKEN_VERIFIED
            ) {
                return route.meta && route.meta.anchor && route.meta.icon;
            }
        });
    }

    pushRoute(item: RouteConfig): void {
        this.$router.push(item);
    }
}
